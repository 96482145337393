<template>
<div class='page'>
   <div class="banner">
            <div class="w">
                <div class="info">
                    <div class="title">  <span style="color:#FB1107">Contact Us</span> </div> 
                    <div class="he"></div>
                    <p class="title1">Our customer care team is here to help</p>
                       <!-- <frrp></frrp> -->
                </div>
            </div>
        </div>
          <myhead :hbg='hbg'></myhead>  
<bread :bbg='bbg'></bread>
   <teo :ApplicationsLists="teo"></teo>
      

     
      
</div>
</template>
<script>

import myhead from '../../components/myHead.vue'
import bread from '../../components/bread.vue'
import teo from './teo.vue'
 
export default {
    components:{bread,myhead,teo
    },
data() {
return {
    hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
teo:{

 
         isshow:true,
        data: [
          {
            img: require("../../assets/img/phone001.png"),
            title:'China Tel',
            info:  "+86-27- 59089998 ext 8020"
          },
          {
            img: require("../../assets/img/e001.png"),
            title:'E-mail',
            info: "info@hlhcnc.com"
          },
          {
            img: require("../../assets/img/o001.png"),
            title:'Office Location',
            info:  "Room 507, Floor 5, Building E, Donghe Center, Wuhan Economic and Technological Development Zone, Wuhan, China."
          }
        ]
    
},
    H1:` <span style="color:#DA251D">Company </span> Profile`,
    info:`We started helping people make parts and products in 2008. HLH is a unique fusion of the West and East. Industry veteran and current CEO Vader Yu from China and James Murphy from the UK set up HLH to rival anything the industry had to offer in terms of quality and service and with the aim to become the fastest and most dependable in the world.<br/> <br/>As our reputation and customer base has grown so has our range of services. We are now one of the only companies in the world to offer such a massive range of prototyping and bespoke low volume manufacturing solutions all under one roof. Our high tech rapid prototyping and production facilities include CNC milling, 3D printing (SLA and SLS), vacuum casting (cast urethanes), sheet metal and rapid tooling and injection molding as well as traditional model making and a range of finishing techniques.`
}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
 
}
</script>
<style  lang="scss" scoped>

 
 .banner{
        background: url('~@/assets/img/AUSbanner01.png') no-repeat;
        
  
        width: 100%;
      
        transition: 0.25s;
        display: flex;
        justify-content: center;
        align-items: center;
    
        // padding-top: 70px;
        .w{
         
            display: flex;
            margin: auto;
          
         
        }
        .btn {
            display: inline-block;
             padding: 10px 15px;
          
            cursor: pointer;
            border: 1px solid #071930;
            // font-size: 16px;
            text-align: center;

            color: #071930;
            // margin-left:64px ;
        }
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #ffffff;
          
      
        }
        .he{
            width: 30%;
            height: 2px;
            margin: 30px auto;
            background: #ffffff6b
        }
        .info {
        text-align: center;
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                } 
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                 font-size: 48px;
                margin-bottom: 0;
                color: #ffffff;
           transition: 0.25s;
           font-weight: bold
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
   
   
    }
    .w{
        // width: 90%;
        // min-width: 330px;
    }
    .title{
           font-size: 30px !important;
    font-weight: bold;
    margin-bottom: 2vw;
    } 
    .title1{
  

     font-size: 14px;
    font-weight: bold;
    width: 55vw;
    line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 598px;
        //  margin-top: 44px;
            background-position: 53% 79% !important;
    }
  
 

}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {


    .w{
        // width: 90%;
    }
    .title{
        font-size: 34px !important;
        margin-bottom: 54px;
    }
    .title1{
            font-size: 19px;
    line-height: 36px;
    width: 41vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 598px;
        background-position: center !important;
        // margin-top: 44px;
    }
    
 
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .w{
        // width: 90%;
    }
      .banner{
        height: 598px;
        background-position: center !important;
    }
    .title{
        font-size: 80px;
        margin-bottom: 54px;
    }
    .title1{
     
    font-size: 22px;
    width: 478px;
    line-height: 42px;

    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
       
       
}



@media screen and (min-width: 1350px) {  
    .w{
        // width: 1200px;
    }
    .title{
        font-size: 100px;
        margin-bottom: 54px;
    }
    .title1{
     
    font-size: 22px;
    width: 478px;
    line-height: 42px;

    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 598px;
            background-position: center !important;
        }
       
    
}
</style>